<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto content-outer-container">
      <div class="mx-auto mainContainer wrap-stampEarnList">
        <div class="titleContainer">
          <div class="title">
            <div class="textTitle">
              <img
                class="mr-2"
                style="vertical-align: sub"
                src="@/assets/images/planreward/Fire.svg"
                alt=""
              />{{ lbl['plan-reward-stamp-earn-tab'] }}
            </div>
          </div>
          <div class="search d-flex">
            <div class="searchContainer">
              <a-input
                type="text"
                class="searchInput"
                v-model="searchInput"
                @keyup.enter="onEnterInput($event)"
                @change="onChangeInput($event)"
                autocomplete="off"
              />
              <img
                @click="onEnterInput($event)"
                :src="require('@/assets/images/Search.png')"
                alt=""
              />
            </div>
            <a-button type="primary" @click="createNew()">
              <img
                :src="require('@/assets/images/btn/plus-square.svg')"
                alt=""
                class="mr-2"
              />
              {{ lbl['plan-reward-stamp-collection-list-add-button'] }}
            </a-button>
          </div>
        </div>
        <hr />
        <div class="filterContainer nopad mt-4">
          <div :class="['filterMain', showFilterMain ? 'Active' : '']">
            <div class="filter" @click="toggle('showFilterMain')">
              {{ lbl['user-permissions-filter'] }}
              <img :src="require('@/assets/images/filter_list.png')" alt="" />
            </div>
            <div class="spaceHover"></div>
            <div class="filterDropdown">
              <div class="filterLeft">
                <div
                  :class="['filterList', item === subFilter ? 'Active' : '']"
                  v-for="item in Object.keys(filter)"
                  @click="changeSubFilter(item)"
                  :key="item"
                >
                  {{ filter[item].label }}
                  <img
                    :src="require('@/assets/images/navigate_next.png')"
                    alt=""
                  />
                </div>
              </div>
              <div class="filterRight">
                <div v-for="item in filter[subFilter].input" :key="item">
                  <div
                    v-if="filter[subFilter].type === 'checkbox'"
                    class="filterList"
                    @click="selectFilter(item)"
                  >
                    <CheckBox
                      :label="item"
                      :value="item"
                      :check="filter[subFilter].value"
                      type="filter"
                    />
                  </div>
                  <div
                    v-if="filter[subFilter].type === 'text'"
                    class="filterList input"
                  >
                    <input
                      type="text"
                      :placeholder="item"
                      v-model="filter[subFilter].value"
                    />
                  </div>
                </div>
              </div>
              <div class="btnFilter">
                <div @click="resetFilter()">
                  {{ lbl['user-permissions-reset'] }}
                </div>
                <button @click="searchFilter()">
                  {{ lbl['user-permissions-ok'] }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="filterItem"
            v-for="(item, index) in Object.keys(allFilter)"
            :key="'filter' + index"
          >
            {{ allFilter[item]
            }}<img
              @click="removeFilter(item)"
              :src="require('@/assets/images/close.png')"
              alt=""
            />
          </div>
        </div>

        <div class="tableContainer wrap-table">
          <a-skeleton v-if="isLoading" active />
          <div v-else>
            <a-table
              :columns="columns"
              :data-source="data"
              rowKey="Id"
              :pagination="{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                pageSizeOptions: pageSizeOptions,
                pageSize: defaultPageSize,
                defaultCurrent: 1,
                showSizeChanger: true,
                hideOnSinglePage: false,
                onChange: onChangePageCampaings,
              }"
              :customRow="openEdit"
            >
              <template slot="isdata" slot-scope="text, record">
                <div class="d-flex">
                  <span class="d-flex align-center">
                    <img
                      v-if="record.Status == 'Active'"
                      src="@/assets/images/item-active-icon.png"
                      alt=""
                      class="item-status-icon mr-2"
                    />
                    <img
                      v-else-if="record.Status == 'Waiting'"
                      src="@/assets/images/item-waiting-icon.png"
                      alt=""
                      class="item-status-icon mr-2"
                    />
                    <img
                      v-else
                      src="@/assets/images/item-inactive-icon.png"
                      alt=""
                      class="item-status-icon mr-2"
                    />
                  </span>
                  {{ record.Name ? record.Name : '' }}
                </div>
              </template>
              <template slot="date" slot-scope="text, record">
                {{
                  record.StartDate ? dateShortFormatUTC(record.StartDate) : ''
                }}
                -
                {{
                  record.ExpireDate ? dateShortFormatUTC(record.ExpireDate) : ''
                }}
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      v-model="showModal"
      :title="
        isCreate
          ? lbl['plan-reward-stamp-collection-manage-create-header']
          : lbl['plan-reward-stamp-collection-manage-edit-header']
      "
      :footer="null"
      centered
      class="earn-modal"
      :class="[currentLocale]"
      id="stampModal"
    >
      <a-form :form="form" @submit.prevent="handleSubmit">
        <!-- Name -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left field-required">
              {{ lbl['plan-reward-point-earn-manage-name'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <a-form-item>
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: value.name,
                    rules: [
                      {
                        required: true,
                        message:
                          lbl[
                            'plan-reward-point-earn-manage-name-required-message'
                          ],
                      },
                    ],
                  },
                ]"
                :disabled="!isCreate && isActive"
                type="text"
                class="form-control w-100"
              />
            </a-form-item>
          </div>
        </div>

        <!-- Description -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left field-required">
              {{ lbl['plan-reward-point-earn-manage-description'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <a-form-item>
              <a-textarea
                v-decorator="[
                  'detail',
                  {
                    initialValue: value.detail,
                    rules: [
                      {
                        required: true,
                        message:
                          lbl[
                            'plan-reward-point-earn-manage-description-required-message'
                          ],
                      },
                    ],
                  },
                ]"
                :disabled="!isCreate && isActive"
                type="text"
                class="form-control w-100"
                :auto-size="{ minRows: 3 }"
              />
            </a-form-item>
          </div>
        </div>

        <!-- Campaign Period -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left">
              {{ lbl['plan-reward-point-earn-manage-period'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                <a-form-item class="text-left">
                  <a-date-picker
                    v-decorator="[
                      'startdate',
                      {
                        initialValue: value.startDate,
                        rules: [
                          {
                            required: true,
                            message: lbl['smart-target-select-date'],
                          },
                        ],
                      },
                    ]"
                    :disabled="!isCreate && isActive"
                    :format="dateFormat"
                    :disabled-date="disabledStartDate"
                    @openChange="handleStartOpenChange"
                    @change="onChangeStart"
                  />
                </a-form-item>
              </div>
              <div class="col-xl-1 col-lg-1 col-md-2 col-sm-12 col-12">
                <div class="d-flex align-center justify-center h-default">
                  {{ lbl['plan-reward-stamp-campaign-manage-period-to'] }}
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                <a-form-item class="text-left">
                  <a-date-picker
                    v-decorator="[
                      'enddate',
                      {
                        initialValue: value.endDate,
                        rules: [
                          {
                            required: true,
                            message: lbl['smart-target-select-date'],
                          },
                        ],
                      },
                    ]"
                    :disabled="!isCreate && isActive"
                    :open="endOpen"
                    :format="dateFormat"
                    :disabled-date="disabledEndDate"
                    @openChange="handleEndOpenChange"
                    @change="onChangeEnd"
                  />
                </a-form-item>
              </div>
            </div>
          </div>
        </div>

        <!-- Accumulate Stamp Ratio -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left">
              {{ lbl['plan-reward-stamp-collection-manage-price-per-stamp'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <a-form-item>
              <div class="div1">
                <a-input-number
                  :min="1"
                  :formatter="
                    value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                  :parser="value => value.replace(/[^0-9]/g, '')"
                  v-decorator="[
                    'stamp_ratio',
                    {
                      initialValue: value.stamp_ratio,
                      rules: [
                        {
                          required: true,
                          message:
                            lbl[
                              'plan-reward-stamp-collection-manage-price-per-stamp-required-message'
                            ],
                        },
                      ],
                    },
                  ]"
                  :disabled="!isCreate && isActive"
                />
              </div>
              <div class="div1">
                {{
                  lbl[
                    'plan-reward-stamp-collection-manage-price-per-stamp-unit'
                  ]
                }}
              </div>
            </a-form-item>
          </div>
        </div>

        <!-- Quantity -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left">
              {{ lbl['plan-reward-stamp-collection-manage-quantity'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <a-form-item>
              <div class="div1">
                <a-input-number
                  :min="1"
                  :formatter="
                    value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                  :parser="value => value.replace(/[^0-9]/g, '')"
                  v-decorator="[
                    'quantity',
                    {
                      initialValue: value.quantity,
                      rules: [
                        {
                          required: true,
                          message:
                            lbl[
                              'plan-reward-stamp-collection-manage-quantity-required-message'
                            ],
                        },
                      ],
                    },
                  ]"
                  :disabled="!isCreate"
                />
              </div>
              <div class="div1">
                {{ lbl['plan-reward-stamp-collection-manage-quantity-unit'] }}
              </div>
            </a-form-item>
          </div>
        </div>

        <!-- Status -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left">
              {{ lbl['plan-reward-point-earn-manage-status'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <a-form-item class="text-left">
              <a-radio-group
                v-decorator="[
                  'status',
                  {
                    initialValue: value.status,
                  },
                ]"
              >
                <a-radio :value="true" class="mr-4">
                  {{ lbl['plan-reward-point-earn-manage-status-active'] }}
                </a-radio>
                <a-radio :value="false">
                  {{ lbl['plan-reward-point-earn-manage-status-inactive'] }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </div>
        </div>

        <!-- Add Rewards -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left">
              {{ lbl['plan-reward-stamp-collection-manage-reward'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <div v-if="isCreate">
              <div v-if="dynamicForm.length > 0">
                <div
                  v-for="item in dynamicForm"
                  :key="item.key"
                  class="w-100 mb-3 row"
                >
                  <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12 col-12">
                    <a-form-item class="field-item text-left">
                      <a-select
                        v-decorator="[
                          'select_campaign[' + item.key + ']',
                          {
                            rules: [
                              {
                                required: true,
                                message: lbl['user-level-please-select'],
                              },
                            ],
                          },
                        ]"
                        @change="
                          e => {
                            changeSelectCampaign(e, item.key)
                          }
                        "
                      >
                        <a-select-option
                          v-for="(burnitem, burnindex) in burnCampaingsList"
                          :key="burnindex"
                          :value="burnitem.id"
                          :disabled="burnitem.disabled"
                        >
                          {{ burnitem.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
                    <a-form-item>
                      <div class="d-flex">
                        <div>
                          {{
                            lbl['plan-reward-stamp-collection-manage-reward-at']
                          }}
                        </div>
                        <a-input-number
                          :min="1"
                          :formatter="
                            value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          "
                          :parser="value => value.replace(/[^0-9]/g, '')"
                          v-decorator="[
                            'collect[' + item.key + ']',
                            {
                              initialValue: item.collect,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          class="collect-input mx-2"
                        />
                        <div>
                          {{
                            lbl[
                              'plan-reward-stamp-collection-manage-reward-at-unit'
                            ]
                          }}
                        </div>
                      </div>
                    </a-form-item>
                  </div>
                  <div
                    class="
                      col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12
                      align-center
                      h-default
                      d-flex
                    "
                  >
                    <img
                      @click="removeRewards(item.key)"
                      class="img-fluid delete-image"
                      :src="require('@/assets/images/delete-item.png')"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="dynamicForm.length != burnCampaingsList.length"
                class="row"
              >
                <div class="col-12 mt-3 text-left">
                  <div class="small">
                    {{
                      lbl['plan-reward-stamp-collection-manage-reward-detail']
                    }}
                  </div>
                  <a-button type="dashed" @click="addRewards">
                    {{
                      lbl[
                        'plan-reward-stamp-collection-manage-reward-add-button'
                      ]
                    }}
                  </a-button>
                </div>
              </div>
              <div
                v-if="
                  dynamicForm.length == burnCampaingsList.length ||
                  lengthBurnCampaings == 0
                "
                class="text-left"
              >
                <div class="small">
                  {{ lbl['plan-reward-stamp-collection-manage-reward-detail'] }}
                </div>
                <div class="text-warning">
                  {{
                    lbl[
                      'plan-reward-stamp-collection-manage-reward-no-available-message'
                    ]
                  }}
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="campaigns.length > 0">
                <div
                  v-for="item in campaigns"
                  :key="item.ID"
                  class="w-100 mb-3 row"
                >
                  <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12 col-12">
                    <a-form-item class="field-item text-left">
                      <a-select v-model="item.ID" :disabled="true">
                        <a-select-option
                          v-for="(burnitem, burnindex) in campaigns"
                          :key="burnindex"
                          :value="burnitem.ID"
                        >
                          {{ burnitem.Name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
                    <a-form-item>
                      <div class="d-flex">
                        <div>
                          {{
                            lbl['plan-reward-stamp-collection-manage-reward-at']
                          }}
                        </div>
                        <a-input-number
                          :min="1"
                          :formatter="
                            value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          "
                          :parser="value => value.replace(/[^0-9]/g, '')"
                          :disabled="true"
                          v-model="item.Quantity"
                          class="collect-input mx-2"
                        />
                        <div>
                          {{
                            lbl[
                              'plan-reward-stamp-collection-manage-reward-at-unit'
                            ]
                          }}
                        </div>
                      </div>
                    </a-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- btn submit -->
        <div class="row field-row btnsubmit">
          <a-form-item>
            <a-button type="primary" html-type="submit">
              {{ lbl['plan-reward-point-burn-manage-save-button'] }}
            </a-button>
          </a-form-item>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import CheckBox from './components/CheckBox.vue'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsPlanReward from '@/core/PlanReward/service/BzbsPlanReward'
import Locale from '@/helper/locale.js'

export default {
  name: 'StampEarnList',
  mixins: [Mixin],
  components: {
    Header,
    CheckBox,
  },
  data: function () {
    return {
      currentLocale: Locale.getLocaleShort(),
      searchInput: '',
      showFilterMain: false,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      subFilter: 'status',
      filter: {
        status: {
          value: null,
          input: ['Active', 'Inactive', 'Waiting'],
          label: 'Status',
          type: 'checkbox',
        },
      },
      isLoading: true,
      data: [],
      dataEarn: [],
      currentPage: 1,
      columns: [
        {
          title: 'Rewards',
          dataIndex: 'Name',
          key: 'plan-reward-point-earn-list-name',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Period',
          dataIndex: 'StartDate',
          key: 'plan-reward-point-earn-list-period',
          align: 'center',
          width: '25%',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Baht / Stamp',
          dataIndex: 'PricePerStamp',
          key: 'plan-reward-stamp-collection-list-price-per-stamp',
          align: 'center',
          width: '25%',
        },
        {
          title: 'Quantity',
          dataIndex: 'Quantity',
          key: 'plan-reward-stamp-collection-list-quantity',
          align: 'center',
          width: '25%',
        },
      ],
      showModal: false,
      isCreate: false,
      value: {
        name: '',
        detail: '',
        status: true,
        startDate: moment(),
        endDate: moment(),
        stamp_ratio: 0,
        quantity: 0,
      },
      minDate: moment(),
      dateFormat: 'DD MMM YYYY',
      endOpen: false,
      currentCampaings: 1,
      totalCampaings: null,
      pageSizeCampaings: 30,
      burnCampaingsList: [],
      dynamicForm: [],
      lengthBurnCampaings: 0,
      editId: 0,
      campaigns: [],
      isActive: null,
    }
  },
  computed: {
    allFilter() {
      return Object.keys(this.filter).reduce((acc, cur) => {
        if (!this.filter[cur].value) return acc
        return (acc = { ...acc, [cur]: this.filter[cur].value })
      }, {})
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'earn-list',
    })
  },
  created() {
    this.init()
    this.setLocale()
  },
  methods: {
    init() {
      this.handleFooter(true)
      this.getCRMPlusProfile()
      this.getCRMPlusStampCollection().then(result => {
        console.log('getCRMPlusStampCollection : ', result)
        this.dataEarn = result
        this.searchFilter()
      })
    },
    setLocale() {
      _.forEach(this.columns, column => {
        column.title = this.lbl[column.key]
      })
    },
    getCRMPlusStampCollection() {
      this.handleLoading(true)
      return new Promise((resolve, reject) => {
        BzbsPlanReward.getCRMPlusStampCollection()
          .then(res => {
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            reject(error)
          })
      })
    },
    getCRMPlusBurnCampaingsByAvailable(available) {
      this.handleLoading(true)
      return new Promise((resolve, reject) => {
        BzbsPlanReward.getCRMPlusBurnCampaingsByAvailable(available)
          .then(res => {
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            reject(error)
          })
      })
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    onChangePageCampaings(page) {
      this.currentCampaings = page
    },
    onChangeStart(date, dateString) {
      this.value.startDate = dateString
      this.startValue = date
    },
    onChangeEnd(date, dateString) {
      this.value.endDate = dateString
      this.endValue = date
    },
    handleStartOpenChange(open) {
      this.value.endDate = ''
      this.endValue = ''
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue
      var date = new Date()
      if (!startValue || !endValue) {
        return startValue.valueOf() < date.setDate(date.getDate() - 1).valueOf()
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue
      var date = new Date()
      if (!endValue || !startValue) {
        return endValue.valueOf() < date.setDate(date.getDate() - 1).valueOf()
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    onEnterInput(event) {
      console.log('onEnterInput : ', event)
      this.searchFilter()
    },
    onChangeInput(event) {
      if (!event.target.value) {
        this.searchFilter()
      }
    },
    toggle(data) {
      this[data] = !this[data]
    },
    changeSubFilter(type) {
      this.subFilter = type
    },
    selectFilter(item) {
      if (
        (this.filter[this.subFilter].value === 'Active' && item === 'Active') ||
        (this.filter[this.subFilter].value === 'Inactive' &&
          item === 'Inactive') ||
        (this.filter[this.subFilter].value === 'Waiting' && item === 'Waiting')
      ) {
        this.filter[this.subFilter].value = null
      } else {
        this.filter[this.subFilter].value = item
      }
    },
    resetFilter() {
      this.filter.status.value = ''
      this.searchFilter()
    },
    removeFilter(type) {
      Object.keys(this.filter).forEach(item => {
        if (type === item) this.filter[item].value = ''
      })
      this.searchFilter()
    },
    searchFilter() {
      console.log('searchFilter', this.filter.status.value)
      this.showFilterMain = false
      let active = null
      let search = this.searchInput ? this.searchInput.toLowerCase() : null
      if (this.filter.status.value === 'Active') active = 'Active'
      else if (this.filter.status.value === 'Inactive') active = 'Inactive'
      else if (this.filter.status.value === 'Waiting') active = 'Waiting'

      if (active != null && search) {
        this.data = _.filter(this.dataEarn, item => {
          if (
            item.Status == active &&
            (item.Name.toLowerCase().includes(search) ||
              item.PricePerStamp == search ||
              item.Quantity == search)
          )
            return item
        })
        this.totalCampaings = this.data.length
      } else if (active != null && !search) {
        this.data = _.filter(this.dataEarn, item => {
          if (item.Status == active) return item
        })
        this.totalCampaings = this.data.length
      } else if (active == null && search) {
        this.data = _.filter(this.dataEarn, item => {
          if (
            item.Name.toLowerCase().includes(search) ||
            item.PricePerStamp == search ||
            item.Quantity == search
          )
            return item
        })
        this.totalCampaings = this.data.length
      } else {
        this.data = this.dataEarn
        this.totalCampaings = this.data.length
      }

      this.isLoading = false
    },
    createNew() {
      this.getCRMPlusBurnCampaingsByAvailable(true).then(result => {
        console.log('getCRMPlusBurnCampaingsByAvailable : ', result)
        if (result.length > 0) {
          this.burnCampaingsList = _.map(result, e => {
            return { id: e.ID, name: e.Name }
          })
          this.lengthBurnCampaings = this.burnCampaingsList.length
        } else {
          this.burnCampaingsList = []
          this.lengthBurnCampaings = 0
        }
      })
      this.form.resetFields()
      this.value = {
        name: '',
        detail: '',
        status: true,
        startDate: moment(),
        endDate: moment(),
        stamp_ratio: 0,
        quantity: 0,
      }
      this.isCreate = true
      this.showModal = true
    },
    openEdit(record) {
      return {
        on: {
          click: () => {
            console.log('record : ', record)
            this.form.resetFields()
            this.isActive = null
            if (!(record.ExpireDate * 1000).valueOf() < new Date().valueOf()) {
              this.isCreate = false
              this.campaigns = record.Campaigns
              this.editId = record.Id
              this.value.name = record.Name
              this.value.detail = record.Detail
              this.value.status = record.Status
                ? record.Status == 'Active'
                : false
              this.isActive = record.Status ? record.Status == 'Active' : false
              this.value.startDate = record.StartDate
                ? moment
                    .unix(record.StartDate)
                    .utc()
                    .format('YYYY-MM-DD HH:mm:ss')
                : moment()
              this.value.endDate = record.ExpireDate
                ? moment
                    .unix(record.ExpireDate)
                    .utc()
                    .format('YYYY-MM-DD HH:mm:ss')
                : moment()
              this.value.stamp_ratio = record.PricePerStamp
              this.value.quantity = record.Quantity

              this.$nextTick(() => {
                this.showModal = true
              })
            }
          },
        },
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('values : ', values)

          if (!this.isCreate) {
            let params = {
              id: this.editId,
              active: values.status,
              name: values.name,
              detail: values.detail,
              startDate: values.startdate
                ? moment(values.startdate).format('YYYY-MM-DDTHH:mm:ss')
                : null,
              expireDate: values.enddate
                ? moment(values.enddate).format('YYYY-MM-DDTHH:mm:ss')
                : null,
              pricePerStamp: values.stamp_ratio ? values.stamp_ratio : 1,
            }
            console.log('params : ', params)
            this.postUpdate(params)
          } else {
            let campaigns = []

            _.forEach(this.dynamicForm, item => {
              campaigns.push({
                id: values.select_campaign[item.key],
                qty: values.collect[item.key],
              })
            })

            let params = {
              name: values.name,
              detail: values.detail,
              active: values.status,
              pricePerStamp: values.stamp_ratio ? values.stamp_ratio : 1,
              quantity: values.quantity ? values.quantity : 0,
              startDate: values.startdate
                ? moment(values.startdate).format('YYYY-MM-DDTHH:mm:ss')
                : null,
              expireDate: values.enddate
                ? moment(values.enddate).format('YYYY-MM-DDTHH:mm:ss')
                : null,
              campaigns: JSON.stringify(campaigns),
            }

            console.log('params : ', params)
            this.$confirm({
              title:
                this.lbl[
                  'plan-reward-stamp-campaign-manage-save-confirm-title'
                ],
              content:
                this.lbl[
                  'plan-reward-stamp-campaign-manage-save-confirm-message'
                ],
              centered: true,
              autoFocusButton: null,
              okText: this.lbl['confirm-box-confirm-button'],
              cancelText: this.lbl['confirm-box-cancel-button'],
              closable: true,
              onOk: () => {
                this.postSave(params)
              },
            })
          }
        }
      })
    },
    postSave(params) {
      return new Promise((resolve, reject) => {
        BzbsPlanReward.postCRMPlusStampCollection(params)
          .then(res => {
            this.success()
            resolve(res.data)
          })
          .catch(error => {
            this.errorResponse(error, true)
            reject(error)
          })
      })
    },
    postUpdate(params) {
      return new Promise((resolve, reject) => {
        BzbsPlanReward.postCRMPlusUpdateStampCollection(params)
          .then(res => {
            this.success()
            resolve(res.data)
          })
          .catch(error => {
            this.errorResponse(error, true)
            reject(error)
          })
      })
    },
    success() {
      if (!this.isCreate) {
        this.$success({
          title: this.lbl['alert-box-success-header'],
          content:
            this.lbl['plan-reward-stamp-campaign-manage-edit-success-message'],
          centered: true,
          autoFocusButton: null,
          closable: true,
          okText: this.lbl['user-permissions-ok'],
          onOk: () => {
            window.location.reload()
          },
        })
      } else {
        this.$success({
          title: this.lbl['alert-box-success-header'],
          content:
            this.lbl[
              'plan-reward-stamp-campaign-manage-create-success-message'
            ],
          centered: true,
          autoFocusButton: null,
          closable: true,
          okText: this.lbl['user-permissions-ok'],
          onOk: () => {
            window.location.reload()
          },
        })
      }
    },
    addRewards() {
      var Id =
        Math.max.apply(
          Math,
          this.dynamicForm.map(function (o) {
            return o.key
          }),
        ) + parseInt(1)
      if (Id === Number.NEGATIVE_INFINITY) Id = parseInt(1)
      this.dynamicForm.push({
        key: Id,
        value: '',
        collect: '',
      })
    },
    removeRewards(key) {
      console.log('removeRewards : ', key)
      this.dynamicForm = _.filter(this.dynamicForm, o => o.key != key)
      this.selectCampaign = _.filter(this.selectCampaign, o => o.key != key)
      var i = 0
      this.burnCampaingsList = _.forEach(this.burnCampaingsList, burn => {
        burn.disabled = false
        i++
        if (this.burnCampaingsList.length == i) {
          this.setDisabled()
        }
      })
    },
    changeSelectCampaign(e, key) {
      this.selectCampaign = _.filter(this.selectCampaign, o => o.id != e)
      this.selectCampaign.push({ id: e, key: key })
      var i = 0
      this.burnCampaingsList = _.forEach(this.burnCampaingsList, burn => {
        burn.disabled = false
        i++
        if (this.burnCampaingsList.length == i) {
          this.setDisabled()
        }
      })
    },
    setDisabled() {
      this.burnCampaingsList = _.forEach(this.burnCampaingsList, burn => {
        _.forEach(this.selectCampaign, item => {
          if (item.id == burn.id) {
            burn.disabled = true
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import './styles/planreward.scss';

.wrap-stampEarnList {
  .wrap-table {
    .btn-sequence {
      padding: 10px;
      width: 40px;
      cursor: move;
      span {
        width: 100%;
        height: 2px;
        display: block;
        background: #000;
        + span {
          margin-top: 2px;
        }
      }
    }
    .box-pagination {
      margin-top: -52px;
      margin-bottom: 20px;
      padding-top: 0;
      .show-page {
        margin-top: 10px;
      }
    }
    .ant-table-wrapper {
      .ant-table-pagination {
        .ant-pagination {
          margin: 16px 0;
        }
      }

      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        font-size: 10px;
      }

      i.anticon.anticon-left {
        vertical-align: 0;
      }

      i.anticon.anticon-right {
        vertical-align: 0;
      }
    }
    .ant-input-number {
      height: 40px;
    }
    .ant-input-number-input-wrap {
      height: 100%;
    }
    .ant-input-number-handler-wrap {
      visibility: hidden;
    }
    .ant-input-number-input {
      padding: 0 11px !important;
      height: 100%;
    }
    .form-button {
      text-align: right;
    }
    .status {
      line-height: 24px;
      padding: 0 4px;
      font-size: 14px;
      border-radius: 4px;
      &.active {
        color: $color-active;
        background: $color-bg-active;
      }
      &.inactive {
        color: $color-inactive;
        background: $color-bg-inactive;
      }
    }
    .reward {
      display: flex;
      align-items: center;
      .reward-image {
        margin: 0 5px;
        width: 50px;
        height: 50px;
      }
      .reward-name {
        flex-grow: 1;
        padding-left: 10px;
      }
    }
    .viewcode {
      cursor: pointer;
      text-align: center;
      color: $color-active;
    }
    .disablestyle {
      color: #a6a8ab !important;

      .reward-image {
        opacity: 0.5;
      }
      .viewcode {
        opacity: 0.5;
      }
    }
  }
}

#stampModal {
  .collect-input {
    width: 80px;
  }
}
</style>
