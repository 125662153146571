<template>
  <label class="container"
    >{{ label }}
    <input
      type="checkbox"
      :checked="isCheck"
      :value="value"
      :disabled="disabled"
    />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    check: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'normal',
    },
    normalCheck: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isCheck() {
      if (this.type === 'filter') {
        return (
          (this.check === 'Active' && this.label === 'Active') ||
          (this.check === 'Inactive' && this.label === 'Inactive') ||
          (this.check === 'Waiting' && this.label === 'Waiting')
        )
      } else {
        return this.normalCheck
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: block;
  position: relative;
  margin-top: 6px;
  padding-left: 32px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #ff9800;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 8px;
  top: 5px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
